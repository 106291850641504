.site-title img.logo {
  width: 1.75em;
  height: 1.75em;
  padding: 0 5px 5px 5px;
  vertical-align: middle;
}

.copyright {
  font-size: 14px;
  color: #828282;
}

article.post img, article.post iframe {
  display: block;
  margin: auto;
  margin-bottom: 1em;
}

article.post {
  counter-reset: figures;
}

article.post figure {
  padding: 1em;
  counter-increment: figures;
}

article.post figcaption {
  font-style: italic;
  margin: 1em 0.5em 0;
  text-align: center;
}

article.post figcaption::before {
  content: "Figure " counter(figures) " - ";
  font-weight: bold;
}

/* Overrides for non-numbered figures */

article.post figure[data-no-number] {
  // Rollback the counter increment if the figure is not numbered
  counter-increment: figures - 1;
}

article.post figure[data-no-number] figcaption::before {
  // Do not display the figure number if the figure is not numbered
  content: "";
}

.author-link {
  font-size: 0.5em;
  vertical-align: middle;
}

.author-link {
  font-size: 14px;
}

.author-github {
  font-size: 10px;
  color: grey;
}

.author-avatar img {
  border-radius: 50%;
  width: 200px;
}

ul.authors-list {
  margin: 0 auto;
  text-align: center;
}

ul.authors-list li {
  display: inline-block;
  vertical-align: top;
  width: 200px;
  text-align: center;
  margin: 10px;
}

@media screen and (max-width: 600px) {
  .video iframe{
    width: 100%;
    height: auto;
  }

  ul.authors-list li {
    width: 45%;
    margin: 5px;
  }
}

img.company {
  width: 1em;
  height: 1em;
}